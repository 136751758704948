












import { Vue, Component } from 'vue-property-decorator';
import { getModule } from 'vuex-module-decorators';
import GeneralModule from '@/store/modules/General';

@Component
export default class Loading extends Vue {
  GeneralInstance = getModule(GeneralModule, this.$store);
}
