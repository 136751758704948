import {
  Module,
  VuexModule,
  Action,
  Mutation,
  MutationAction,
} from 'vuex-module-decorators';

const defaultAlert = () => ({
  isAlert: false,
  alertMsg: '',
  alertColor: '',
});

@Module({ name: 'General' })
export default class General extends VuexModule {
  alert = defaultAlert();

  isLoading = false;

  @Mutation
  mutateAlert(data: any) {
    this.alert = data;
  }

  @Action({ commit: 'mutateAlert' })
  async ShowAlert(alert: any) {
    setTimeout(() => {
      this.context.dispatch('CloseAlert');
    }, 2500);
    return alert;
  }

  @Action({ commit: 'mutateAlert' })
  async CloseAlert() {
    return defaultAlert();
  }

  @MutationAction({ mutate: ['isLoading'] })
  async toggleLoading(val: boolean) {
    return {
      isLoading: val,
    };
  }
}
