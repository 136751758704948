























































































































import { Vue, Component, Prop } from 'vue-property-decorator';
import { getModule } from 'vuex-module-decorators';
import AuthModule from '@/store/modules/Auth';
import GeneralModule from '@/store/modules/General';
import serviceUtils from '@/services/utils';

@Component({
  components: {
  },
})
export default class Navbar extends Vue {
  @Prop() getToken!: Function;

  @Prop() onLogout!: Function;

  @Prop() filterHandle!: Function;

  AuthInstance = getModule(AuthModule, this.$store);

  GeneralInstance = getModule(GeneralModule, this.$store)

  username = '';

  password = '';

  alive = serviceUtils.getUserPersistent().user

  balance = 0;

  componentKey = 0;

  async Login() {
    const res = await this.AuthInstance.Login({ username: this.username, password: this.password });
    if (res.status) {
      this.$swal({
        icon: res.status.code === 0 ? 'success' : 'error',
        title: res.status.message,
      });
    }
    if (res) {
      this.username = '';
      this.password = '';
      this.alive = serviceUtils.getUserPersistent().user;
      this.getToken(serviceUtils.getUserPersistent().token);
      this.getBalance();
    }
    this.componentKey += 1;
  }

  async Logout() {
    this.alive = false;
    await this.AuthInstance.Logout();
    this.onLogout(serviceUtils.getUserPersistent().token);
    this.componentKey += 1;
  }

  async getBalance() {
    const res = await this.AuthInstance.getBalance(serviceUtils.getUserPersistent().token);
    this.balance = res.balance;
  }

  currencyFormatter(balance: number) {
    const x = balance;
    if (x === 0) {
      return '0.00';
    }

    if (!x) {
      return x;
    }

    return x.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,');
  }
}
