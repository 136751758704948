export const ENV = 'dev';
export const GENERIC_URL = {
  local: {
    web: 'http://localhost:8080/',
    api: 'https://api-staging.ambprovider-hub.com/',
    gameFrame: 'http://localhost:8081/',
  },
  dev: {
    web: 'https://staging.ambsupergames.com/',
    api: 'https://api-staging.ambprovider-hub.com/',
    gameFrame: 'https://play-sit.ambsupergames.com/',
  },
  uat: {
    web: 'https://uat.ambsupergames.com/',
    api: 'https://api-uat.ambprovider-hub.com/',
    gameFrame: 'https://play-uat.ambsupergames.com/',
  },
  prod: {
    web: 'https://ambsupergames.com/',
    api: 'https://api-prod.ambprovider-hub.com/',
    gameFrame: 'https://play.ambsupergames.com/',
  },
};

export const BASE_URL = GENERIC_URL[ENV].api;
export const WEB_GAME_URL = GENERIC_URL[ENV].gameFrame;
export const WEB_BASE_URL = GENERIC_URL[ENV].web;
export const M_GET = 'GET';
export const M_POST = 'POST';
export const M_PUT = 'PUT';
export const M_DELETE = 'DELETE';
export const SECRET_KEY = 'ambsupergame-visa-to-access';
export const ASG_SECRET = 'asg-secret-to-verify';
export const PUBLIC_ASG_KEY = '-----BEGIN PUBLIC KEY-----MIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKBgQCZdY175sVrKhdkJu7Gv99zwv9b3RFxXbrzNfdiX6g01RgjfAvqt4aFSTfeqEe9/tBlANRauOLEpjmrj/mPX6jEhXe5qoIdIJ2Rv4ZH8yqNp1VkzQRvR6tYLTRvAt5nPebIUrBUn217kPYV+f5jsZm4ryT73cyQ0nc8btSY7l8vDQIDAQAB-----END PUBLIC KEY-----';

export const LOGIN = 'auth/login';
export const GET_GAMES = 'game/list';
export const GET_VERDOR = 'provider/list';
export const GET_GAMES_DEMO = 'game/list/demo';
export const GET_VERDOR_DEMO = 'provider/list/demo';
export const LAUNCH_GAME = 'game/launch';
export const LAUNCH_GAME_DEMO = 'game/launchdemo';
export const GET_BALANCE = 'user/balance';
