import Vue from 'vue';
import Vuex from 'vuex';
import General from './modules/General';
import Auth from './modules/Auth';
import Vendor from './modules/Vendors';
import Game from './modules/Games';

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    General,
    Auth,
    Vendor,
    Game,
  },
});
